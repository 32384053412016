import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { ref, watch, computed } from 'vue';
export default {
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    const navTitle = ref('');
    const leftArrow = ref(false);
    watch(() => route, val => {
      const {
        path,
        query,
        meta
      } = val;
      if (path.indexOf('writeForm') > -1) {
        //订单填写
        if (query.type == 1) {
          navTitle.value = '专家预约';
        } else if (query.type == 2) {
          navTitle.value = '重疾住院手术安排';
        } else if (query.type == 3) {
          navTitle.value = '检查加急服务';
        }
      } else {
        navTitle.value = meta.title || '就医';
      }
      // console.log(val,9)
      // navTitle.value = val.title || '就医';
      leftArrow.value = meta.showNav;
    }, {
      immediate: true
    });
    const onClickLeft = () => {
      router.back(-1);
    };
    const onClickRight = () => {};
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createBlock(_component_van_nav_bar, {
        fixed: "",
        title: navTitle.value,
        "left-text": "",
        "left-arrow": leftArrow.value,
        placeholder: "",
        onClickLeft: onClickLeft,
        onClickRight: onClickRight
      }, null, 8, ["title", "left-arrow"]);
    };
  }
};