import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import { ref, reactive } from "vue";
import cache from '@/utils/cache';
export default {
  setup(__props) {
    const active = ref(0);
    const tabbarData = reactive([{
      icon: "wap-home-o",
      title: "首页",
      to: {
        name: "home"
      }
    }, {
      icon: "orders-o",
      title: "订单",
      to: {
        name: "order"
      }
    }, {
      icon: "user-o",
      title: "我的",
      to: {
        name: "mine"
      }
    }]);
    const onChange = index => {
      if (cache.session.getJSON('loginInfo') && cache.session.getJSON('loginInfo').access_token) {
        //存在token
        active.value = index;
      } else {
        active.value = index;
      }
    };
    return (_ctx, _cache) => {
      const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
      const _component_van_tabbar = _resolveComponent("van-tabbar");
      return _openBlock(), _createBlock(_component_van_tabbar, {
        modelValue: active.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => active.value = $event),
        placeholder: true,
        route: true,
        fixed: "",
        onChange: onChange
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabbarData), (item, index) => {
          return _openBlock(), _createBlock(_component_van_tabbar_item, {
            key: index,
            icon: item.icon,
            to: item.to
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(item.title), 1)]),
            _: 2
          }, 1032, ["icon", "to"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};