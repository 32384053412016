import {
	createRouter,
	createWebHashHistory
} from 'vue-router'

import routes from "./routes";
import cache from '@/utils/cache';
const router = createRouter({
	history: createWebHashHistory(),
	routes
})
router.beforeEach((to, from, next) => {
	// console.log(to,1)
	let bcToken = cache.session.get('bcToken');//获取管理端：bcToken
	if(to.meta.isBc){//是否管理端
		if(to.name !== 'login' && !bcToken){//非login && 未登录
			next({name:'login'});//登录页
		}else if(to.name == 'login' && bcToken){//是login && 已登录
			next({name:'bcorder'});//订单页
		}else{
			next();
		};
	}else{//用户端
		next();
	};
	document.title = '国网山西家庭医生服务';
	// const title = to.meta && to.meta.title;
	// if (title) {
	// }
});
export default router