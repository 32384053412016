import request from '@/utils/request'

export function validateToken(query){//就诊人登录验证
	return request({
		url:'/patient/getToken',
		method: 'get',
		params: query,
		headers:{
			isToken: false
		}
	})
}
/* 客户端start */

export function getAreaList(query) { //获取地区列表三级地区的
	return request({
		url: '/query/selectRegionAllTree',
		method: 'get',
		params: query
	})
}
export function getLevel2HospitalList(query) { //二级区域名称获取医院列表
	return request({
		url: '/query/selectHospitalsByReg2',
		method: 'get',
		params: query
	})
}
export function getLevel3HospitalList(query) { //三级区域名称获取医院列表
	return request({
		url: '/query/selectHospitalsByReg3',
		method: 'get',
		params: query
	})
}
export function getDeptList(query) { //医院id获取科室列表
	return request({
		url: '/query/selectDepartmentsByHospitalId',
		method: 'get',
		params: query
	})
}
export function getNameDict(query) { //名称获取字典列表
	return request({
		url: '/query/selectDictByType',
		method: 'get',
		params: query
	})
}



export function getMemberList() { //获取成员列表
	return request({
		url: '/patient/selectPatientsList',
		method: 'get'
	})
}
export function saveMemberInfo(data) { //编辑成员信息
	return request({
		url: '/patient/savePatient',
		method: 'post',
		data: data
	})
}
export function saveOrderInfo(data) { //保存订单
	return request({
		url: '/medical/medicalReservation/saveMedicalReservation',
		method: 'post',
		data: data
	})
}
export function updateOrderInfo(data) { //修改订单
	return request({
		url: '/medical/medicalReservation/updateInfoByDcCustomer',
		method: 'post',
		data: data
	})
}
export function cancelOrder(data){//取消订单
	return request({
		url:'/medical/medicalReservation/cancelByDcCustomer',
		method:'post',
		data:data
	})
}
export function interestInfo(){//我的权益信息
	return request({
		url:'/query/selectMyInfo',
		method:'get'
	})
}
export function getOrderList(query) { //获取订单列表
	return request({
		url: '/query/selectOrderList',
		method: 'get',
		params:query
	})
}

export function serverLog(query){//记录预约数
	return request({
		url:'query/queryServiceLog',
		method:'get',
		params:query
	})
}
/* 客户端end */

/* 管理端start */
export function getVerifyCode(){
	return request({
		url:'verifyCode/getVerifyCode',
		method:'get'
	})
}

export function login(data) {//登录
  return request({
    url: '/operator/login',
    method: "post",
    data,
		headers:{
			isToken: false
		}
  });
}

export function getBcOrder(query){
	return request({
		url:'/operator/selectOrderByParam',
		method:'get',
		params:query,
		headers:{
			isToken: false
		},
		isBc:true
	})
}

export function saveBcOrderConfirm(data){//操作员确认服务
	return request({
		url:'/operator/confirmService',
		method:'post',
		data,
		headers:{
			isToken: false
		}
	})
}
export function saveBcOrderFinish(data){//操作员服务完成
	return request({
		url:'/operator/finishService',
		method:'post',
		data,
		headers:{
			isToken: false
		}
	})
}
export function getBcFiles(query){//获取文件信息
	return request({
		url:'/operator/selectFilesByMedicalResId',
		method:'get',
		params:query,
		headers:{
			isToken: false
		}
	})
}
export function getLookFiles(query){//查看文件
	return request({
		url:'/operator/pullFileByFileUrl',
		method:'get',
		params:query,
		headers:{
			isToken: false
		}
	})
}
/* 管理端end */