import axios from 'axios';
import { Dialog } from 'vant';
import 'vant/es/dialog/style';
import {Toast} from 'vant';
import cache from '@/utils/cache';
// import router from '@/router'
import router from '../router'

function tansParams(params) {
	let result = ''
	for (const propName of Object.keys(params)) {
		const value = params[propName];
		var part = encodeURIComponent(propName) + "=";
		if (value !== null && value !== "" && typeof(value) !== "undefined") {
			if (typeof value === 'object') {
				for (const key of Object.keys(value)) {
					if (value[key] !== null && value[key] !== "" && typeof(value[key]) !== 'undefined') {
						let params = propName + '[' + key + ']';
						var subPart = encodeURIComponent(params) + "=";
						result += subPart + encodeURIComponent(value[key]) + "&";
					}
				}
			} else {
				result += part + encodeURIComponent(value) + "&";
			}
		}
	}
	return result
};

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

const service = axios.create({
	// baseURL: 'http://192.168.2.197:9099/api',
	// baseURL: 'https://ykylhealth.com/api',
	baseURL: '/api',
	withCredentials: true,
	timeout: 80000
});
// request拦截器
service.interceptors.request.use((config) => {
		// 是否需要设置 token
		const isToken = (config.headers || {}).isToken === false;
		// 是否需要防止数据重复提交
		const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
		//获取登录信息
		let loginInfo = cache.session.getJSON('loginInfo');
		if (loginInfo && loginInfo.access_token && !isToken) {
			config.params = {//添加参数
				...config.params,
				token:loginInfo.access_token
			};
		};
		// get请求映射params参数
		if (config.method === 'get' && config.params) {
			/* let url = config.url + '?' + tansParams(config.params);
			url = url.slice(0, -1);
			config.params = {};
			config.url = url; */
		};
		//post请求
		if ((config.method === 'post' || config.method === 'put')) {
			config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
		};
		return config;
	},
	(error) => {
		return Promise.reject(error);
	});

// 响应拦截器
service.interceptors.response.use((response) => {
		const {msg,data,code} = response.data;
		const {config} = response;
		if (code == -1) {
			Dialog.alert({
				title:'提示',
			  message: msg,
			  theme: 'round-button',
			}).then(() => {
				if(config.isBc || false){//是后端跳转登录页
					//清除缓存:token
					cache.session.remove('bcToken');
					router.replace('/login');
				};
			  // on close
			});
			return Promise.resolve(response.data);//提示信息
		} else if(code === 200){//成功
			return Promise.resolve(response.data);
		}else{
			return Promise.resolve(response.data);
		};
	},
	(error) => {
		let {message} = error;
		if (message == "Network Error") {
			message = "后端接口连接异常";
		} else if (message.includes("timeout")) {
			message = "系统接口请求超时";
		}else if(message.includes('404')){
			message = "系统接口未找到";
		};
		return Promise.reject(error);
	});
export default service