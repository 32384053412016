import { createApp } from 'vue'
import 'amfe-flexible';
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'

const app = createApp(App);

import { Toast } from 'vant';
import 'vant/es/toast/style';
app.config.globalProperties.$toast = Toast;
app.use(store);
app.use(router);
app.mount('#app');
