import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ba76f3e6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-wrapper"
};
import tabbar from "@/components/TabBar.vue";
import NavBar from "@/components/Navbar.vue";
import { ref, watch, reactive, computed } from 'vue';
import { useRoute } from 'vue-router';
export default {
  setup(__props) {
    const route = useRoute();
    //是否显示tabbar
    const state = reactive({
      showNav: false
    });
    watch(() => route.meta, val => {
      state.showNav = val.showNav ? val.showNav : false;
    }, {
      immediate: true
    });
    const cachedViews = computed(() => {
      return '';
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_unref(state).showNav ? (_openBlock(), _createBlock(NavBar, {
        key: 0
      })) : _createCommentVNode("", true), _createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
        _: 1
      }), !_unref(state).showNav ? (_openBlock(), _createBlock(tabbar, {
        key: 1
      })) : _createCommentVNode("", true)]);
    };
  }
};