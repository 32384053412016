import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref, watch, reactive, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import cache from '@/utils/cache';
import { validateToken } from '@/api/index';
export default {
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const {
      proxy
    } = getCurrentInstance();
    const param = ref('');
    router.isReady().then(() => {
      const {
        param
      } = route.query;
      if (!cache.session.getJSON('loginInfo') && param) {
        //存在
        valadateParam(param);
      }
      ;
    });
    const valadateParam = info => {
      validateToken({
        param: info
      }).then(res => {
        const {
          code,
          msg,
          data
        } = res;
        if (code === 200) {
          if (data) {
            //设置登录信息
            cache.session.setJSON('loginInfo', data);
          }
          ;
        } else {
          proxy.$toast.fail(msg);
        }
      }).catch(err => {});
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};