import Layout from "@/layout/index.vue";
import Home from "@/views/home/index.vue";
const routes = [{
	path: "/",
	name: "root",
	component: Layout,
	redirect: {
		name: "home"
	},
	children: [{
			path: "home",
			name: "home",
			component: Home,
			meta: {
				title: "首页",
				showNav:false
			}
		},
		{
			path: "order",
			name: "order",
			component: () => import("@/views/order/index.vue"),
			meta: {
				title: "服务订单",
				showNav:false
			}
		},
		{
			path: "mine",
			name: "mine",
			component: () => import("@/views/mine/index.vue"),
			meta: {
				title: "我的",
				showNav:false
			}
		},
		{
			path: '/orderDetails',
			name: 'orderDetails',
			component: () => import('../views/order/details'),
			meta: {
				title: '订单详情',
				showNav: true
			}
		}, {
			path: '/orderSuccess',
			name: 'orderSuccess',
			component: () => import('../views/order/orderSuccess'),
			meta: {
				title: '提交成功',
				showNav: true
			}
		}, {
			path: '/writeForm',
			name: 'writeForm',
			component: () => import('../views/writeForm'),
			meta: {
				title: '就医',
				showNav: true
			}
		}, {
			path: '/editUser',
			name: 'editUser',
			component: () => import('../views/mine/editUser'),
			meta: {
				title: '编辑',
				showNav: true
			}
		}
	]
},{
	path:'/login',
	name:'login',
	component: () => import('../views/admin/login/index'),
	meta:{
		title:'登录',
		isBc:true
	}
},{
	path:'/bcorder',
	name:'bcorder',
	component: () => import('../views/admin/order/index'),
	meta:{
		title:'订单列表',
		isBc:true
	}
},{
	path:'/bcorderDetails',
	name:'bcorderDetails',
	component: () => import('../views/admin/orderDetail/index'),
	meta:{
		title:'订单详情',
		isBc:true
	}
}];

export default routes;